import React, { useContext, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import ButtonPrimary from "../buttons/ButtonPrimary";
import "./CreateClubFundraiser.css";
import { useNavigate, useParams } from "react-router-dom";
import ProfileAccount from "../account/ProfileAccount";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import SuccesNotification from "../../hooks/SuccesNotification";
import Parser from "html-react-parser";
import ApiContext from "../../providers/Api/ApiContext";

export default function CreateClubFundraiser() {
  const { api, soccerApi } = useContext(ApiContext);
  const navigate = useNavigate();
  const { sportId, leagueId } = useParams();
  const [loader, showLoader, hideLoader] = useLoading();
  const [leagues, setLeagues] = useState([]);
  const [sports, setSports] = useState([]);
  const [contests, setContests] = useState([]);
  const [selectedData, setSelectedData] = useState({
    sport_id: 0,
    league_id: 0,
    contest_id: 0,
  });
  const [alert, alert_notify] = useAlert();
  const [loading, setLoading] = useState(true);
  const [staticContent, setStaticContent] = useState("");
  const [sportData, setSportdata] = useState([
    {
      sport_id: 1,
      sport_name: "Soccer",
      leagues: [
        {
          league_id: "75d42a12-bbf6-43dc-86d5-a23efb413e1f",
          league_name: "Premier League 24/25",
        },
        {
          league_id: "d4569ba4-bced-4b15-8502-77cfdbaae42d",
          league_name: "UEFA Nations League 24/25",
        },
      ],
    },
  ]);
  const [successStatus, setSuccesStatus] = useState(false);
  const [validSport, setValidSport] = useState(false);
  const [validLeague, setValidLeague] = useState(false);
  const [validOrgName, setValidOrgName] = useState(false);
  const [validEntryFee, setValidEntryFee] = useState(false);
  const [validAccountName, setValidAccountName] = useState(false);
  const [validSortCode, setValidSortCode] = useState(false);
  const [validAccNumb, setValidAccNumb] = useState(false);
  const [validCompTitle, setValidCompTitle] = useState(false);
  const [currency, setCurrency] = useState("EUR");
  const [cfLabels, setCfLabels] = useState([]);

  const [entryFee, setEntryFee] = useState(1);
  const [entrants, setEntrants] = useState(2);
  const [sportsFirst, setSportsFirst] = useState(50);
  const [sportsSecond, setSportsSecond] = useState(25);
  const [sportsThird, setSportsThird] = useState(15);
  const [sportsClub, setSportsClub] = useState(10);
  const [rake, setRake] = useState(10);

  const getClubFundraiserData = () => {
    var create_club_fundraiser_sports = document.getElementById(
      "create_club_fundraiser_sports"
    ).value;
    var create_club_fundraiser_leagues = document.getElementById(
      "create_club_fundraiser_leagues"
    ).value;
    var organisation_name_valid =
      document.getElementById("organisation_name").value;
    var account_name_valid = document.getElementById("account_name").value;
    var sort_code_valid = document.getElementById("sort_code").value;
    var account_number_valid = document.getElementById("account_number").value;
    var create_club_fundraiser_entry_fee = document.getElementById(
      "create_club_fundraiser_entry_fee"
    ).value;
    var competition_title_valid =
      document.getElementById("competition_title").value;

    if (organisation_name_valid !== "") {
      setValidOrgName(true);
    } else {
      setValidOrgName(false);
    }

    if (account_name_valid !== "") {
      setValidAccountName(true);
    } else {
      setValidAccountName(false);
    }

    if (sort_code_valid !== "") {
      setValidSortCode(true);
    } else {
      setValidSortCode(false);
    }

    if (account_number_valid !== "") {
      setValidAccNumb(true);
    } else {
      setValidAccNumb(false);
    }

    if (competition_title_valid !== "") {
      setValidCompTitle(true);
    } else {
      setValidCompTitle(false);
    }

    if (create_club_fundraiser_sports !== "0") {
      setValidSport(true);
    } else {
      setValidSport(false);
    }

    if (create_club_fundraiser_leagues !== "0") {
      setValidLeague(true);
    } else {
      setValidLeague(false);
    }

    if (create_club_fundraiser_entry_fee !== "0") {
      setValidEntryFee(true);
    } else {
      setValidEntryFee(false);
    }
  };

  const calculateMaxPayout = (percentage) => {
    return (((entryFee * entrants) / 100) * percentage).toFixed(2);
  };

  const calculateRake = (percentage) => {
    return ((calculateMaxPayout(sportsClub) / 100) * percentage).toFixed(2);
  };

  const submitCreateClubFundraiser = (e) => {
    e.preventDefault();
    var create_club_fundraiser_url = "/contest/request_contest";
    var organisation_name = document.getElementById("organisation_name").value;
    var account_name = document.getElementById("account_name").value;
    var sort_code = document.getElementById("sort_code").value;
    var account_number = document.getElementById("account_number").value;
    var competition_title = document.getElementById("competition_title").value;
    var create_club_fundraiser_sports = document.getElementById(
      "create_club_fundraiser_sports"
    ).value;
    var create_club_fundraiser_leagues = document.getElementById(
      "create_club_fundraiser_leagues"
    ).value;
    var create_club_fundraiser_entry_fee = document.getElementById(
      "create_club_fundraiser_entry_fee"
    ).value;
    var create_club_fundraiser_entrants = document.getElementById(
      "create_club_fundraiser_entrants"
    ).value;
    var create_club_fundraiser_sports_first = document.getElementById(
      "create_club_fundraiser_sports_first"
    ).value;
    var create_club_fundraiser_sports_second = document.getElementById(
      "create_club_fundraiser_sports_second"
    ).value;
    var create_club_fundraiser_sports_third = document.getElementById(
      "create_club_fundraiser_sports_third"
    ).value;
    var create_club_fundraiser_club = document.getElementById(
      "create_club_fundraiser_club"
    ).value;

    if (create_club_fundraiser_entry_fee === "") {
      create_club_fundraiser_entry_fee = 1;
    }
    if (create_club_fundraiser_entrants === "") {
      create_club_fundraiser_entrants = 2;
    }
    if (create_club_fundraiser_sports_first === "") {
      create_club_fundraiser_sports_first = 0.5;
    }
    if (create_club_fundraiser_sports_second === "") {
      create_club_fundraiser_sports_second = 0.25;
    }
    if (create_club_fundraiser_sports_third === "") {
      create_club_fundraiser_sports_third = 0.15;
    }
    if (create_club_fundraiser_club === "") {
      create_club_fundraiser_club = 0.1;
    }
    if (
      Number(sportsFirst) +
        Number(sportsSecond) +
        Number(sportsThird) +
        Number(sportsClub) !==
      100
    ) {
      alert_notify("error", "Payouts must equal 100%");
      return false;
    }

    if (
      validOrgName &&
      validAccountName &&
      validSortCode &&
      validAccNumb &&
      validCompTitle &&
      validEntryFee
    ) {
      var body = {
        organisation_name: organisation_name,
        bankaccount_name: account_name,
        bankaccount_number: sort_code,
        bankaccount_sortcode: account_number,
        competition_title: competition_title,
        contest_type: "club",
        league_id: selectedData.league_id,
        contest_id: selectedData.contest_id,
        entry_fee: create_club_fundraiser_entry_fee,
        num_of_entrants: create_club_fundraiser_entrants,
        payout_1st: create_club_fundraiser_sports_first,
        payout_2nd: create_club_fundraiser_sports_second,
        payout_3rd: create_club_fundraiser_sports_third,
        payout_club: create_club_fundraiser_club,
        rake: rake,
        sport_id: Number(selectedData.sport_id),
        currency: currency,
      };
      showLoader();
      soccerApi
        .post(create_club_fundraiser_url, body)
        .then((response) => {
          if (response.status === 200) {
            setSuccesStatus(true);
            alert_notify(
              "success",
              "Club Fundraiser request has been made successfully."
            );
          }
          hideLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/login");
          } else {
            alert_notify("error", err.response.data.Message);
          }
          hideLoader();
        });
    } else {
      alert_notify("error", "All fields required !");
    }
  };

  const clubFundraiserLabels = () => {
    var cf_labels_url = "/api/static_content/get_all_labels";
    var body = {
      page_name: "create club fundraiser",
    };
    showLoader();
    api
      .post(cf_labels_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data.content;
          setCfLabels(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };
  const getStaticContent = () => {
    var cf_static_content_url = "/api/static_content/get_all_static_content";
    var body = {
      page_meta_key: "club_fundraiser",
    };
    showLoader();
    api
      .post(cf_static_content_url, body)
      .then((response) => {
        if (response.status === 200) {
          var data = response.data.Data?.content?.page_content;
          setStaticContent(data);
        }
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        hideLoader();
      });
  };

  const getContestByLeagueId = (leagueId) => {
    var content_url = `/contest/fetch_contest/${leagueId}`;
    showLoader();
    soccerApi
      .get(content_url)
      .then((response) => {
        if (response.status === 200) {
          setContests(response.data.data.contest);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  useEffect(() => {
    clubFundraiserLabels();
    getStaticContent();
    setSuccesStatus(false);
  }, [leagueId]);

  const handleCurrencyChange = (e) => {
    var currency = e.target.value;
    setCurrency(currency);
  };

  return (
    <React.Fragment>
      <Header />
      <ProfileAccount
        headerValue={
          !cfLabels.create_club_fundraiser_page_label
            ? "CREATE CLUB FUNDRAISER"
            : cfLabels.create_club_fundraiser_page_label.label_name
        }
      />
      <div className="create_club_fundraiser_container primary-background">
        {successStatus ? (
          <SuccesNotification content="Club Fundraising sign up has been sent successfully." />
        ) : (
          <div className="page_content_center">
            {Parser(staticContent)}
            <form
              id="form_club_fundraiser"
              onSubmit={submitCreateClubFundraiser}
            >
              <div className="create_club_fundraiser_info">
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="organisation_name"
                      className="organization_name_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_organisation_name
                          ? "Organisation Name"
                          : cfLabels.create_club_fundraiser_organisation_name
                              .label_name
                      }
                    />
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="account_name"
                      className="account_name_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_account_name
                          ? "Account Name"
                          : cfLabels.create_club_fundraiser_account_name
                              .label_name
                      }
                    />
                  </div>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="sort_code"
                      className="sort_code_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_sort_code
                          ? "Sort Code"
                          : cfLabels.create_club_fundraiser_sort_code.label_name
                      }
                    />
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6">
                    <input
                      type="text"
                      id="account_number"
                      className="account_number_input"
                      maxLength="50"
                      placeholder={
                        !cfLabels.create_club_fundraiser_account_number
                          ? "Account Number"
                          : cfLabels.create_club_fundraiser_account_number
                              .label_name
                      }
                    />
                  </div>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-6">
                    <select
                      className="currency_select"
                      id="fundraiser_currency"
                      placeholder="Currency"
                      onChange={handleCurrencyChange}
                      defaultValue={currency}
                    >
                      <option value="EUR">EUR</option>
                      <option value="CAD">CAD</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="club_fundraiser_selections">
                <div className="club_fundraiser_select_options_tittle col-12">
                  <span className="fw-normal club_fundraiser_details text-white">
                    Please select one option only from each of the following:
                  </span>
                </div>
                <div className="row mt-row">
                  <div className="col-12 col-xl-6 col-lg-5">
                    <div className="col-12 mt-4">
                      <input
                        type="text"
                        id="competition_title"
                        className="competition_title_input"
                        maxLength="50"
                        placeholder={
                          !cfLabels.create_club_fundraiser_competition_title
                            ? "Competition Title"
                            : cfLabels.create_club_fundraiser_competition_title
                                .label_name
                        }
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        id="create_club_fundraiser_sports"
                        onChange={(x) =>
                          setSelectedData({
                            ...selectedData,
                            sport_id: x.target.value,
                          })
                        }
                        defaultValue={sportId}
                      >
                        <option value="0">
                          {!cfLabels.create_club_fundraiser_sport
                            ? "Sport"
                            : cfLabels.create_club_fundraiser_sport.label_name}
                        </option>
                        {sportData.map((sport, index) => {
                          var selected =
                            sport.sport_id == sportId ? true : false;
                          return (
                            <option
                              data-id={sport.sport_id}
                              key={index}
                              value={sport.sport_id}
                              selected={selected}
                            >
                              {sport.sport_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        id="create_club_fundraiser_leagues"
                        onChange={(x) => {
                          getContestByLeagueId(x.target.value);
                          setSelectedData({
                            ...selectedData,
                            league_id: x.target.value,
                          });
                        }}
                      >
                        <option value="0">
                          {!cfLabels.create_club_fundraiser_league
                            ? "League"
                            : cfLabels.create_club_fundraiser_league.label_name}
                        </option>
                        {sportData
                          .filter((x) => x.sport_id == selectedData.sport_id)[0]
                          ?.leagues?.map((league, index) => {
                            var selected =
                              league.league_id == selectedData.league_id
                                ? true
                                : false;
                            return (
                              <option
                                data-id={league.league_id}
                                data-value={league.league_id}
                                value={league.league_id}
                                key={index}
                                selected={selected}
                              >
                                {league.league_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-12 mt-4">
                      <select
                        className="create_club_fundraiser_select"
                        id="create_club_fundraiser_contest"
                        onChange={(x) =>
                          setSelectedData({
                            ...selectedData,
                            contest_id: x.target.value,
                          })
                        }
                      >
                        <option value="0">Select Contest</option>
                        {contests.map((contest, index) => {
                          return (
                            <option
                              data-id={contest.contest_id}
                              value={contest.contest_id}
                              data-value={contest.contest_id}
                              key={index}
                            >
                              ({contest.contest_uid}) {contest.contest_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        id="create_club_fundraiser_entry_fee"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cfLabels.create_club_fundraiser_entryfee
                            ? "Entry Fee (" + currency + ")"
                            : cfLabels.create_club_fundraiser_entryfee
                                .label_name +
                              " (" +
                              currency +
                              ")"
                        }
                        data-type="entryfee"
                        step="1"
                        min="1"
                        onChange={(e) => {
                          return setEntryFee(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <input
                        type="number"
                        id="create_club_fundraiser_entrants"
                        className="create_club_fundraiser_select"
                        placeholder={
                          !cfLabels.create_club_fundraiser_entrants
                            ? "Entrants"
                            : cfLabels.create_club_fundraiser_entrants
                                .label_name
                        }
                        data-type="entrants"
                        step="1"
                        min="2"
                        onChange={(e) => {
                          return setEntrants(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-7">
                    <div className="col-12 mt-4">
                      <span
                        className={
                          Number(sportsFirst) +
                            Number(sportsSecond) +
                            Number(sportsThird) +
                            Number(sportsClub) ===
                          100
                            ? "text-white"
                            : "red_color"
                        }
                      >
                        {!cfLabels.create_club_fundraiser_payouts
                          ? "Payouts"
                          : cfLabels.create_club_fundraiser_payouts
                              .label_name}{" "}
                        {Number(sportsFirst) +
                          Number(sportsSecond) +
                          Number(sportsThird) +
                          Number(sportsClub)}
                        (%)
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_1stplace
                          ? "1st Place"
                          : cfLabels.create_club_fundraiser_1stplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_first"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="50"
                        value={sportsFirst}
                        data-type="sports_first"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          return setSportsFirst(e.target.value);
                        }}
                      />

                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {currency} {calculateMaxPayout(sportsFirst)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_2ndplace
                          ? "2nd Place"
                          : cfLabels.create_club_fundraiser_2ndplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_second"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="25"
                        value={sportsSecond}
                        data-type="sports_second"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          return setSportsSecond(e.target.value);
                        }}
                      />
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {currency} {calculateMaxPayout(sportsSecond)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col fundraiser_currency_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_3rdplace
                          ? "3rd Place"
                          : cfLabels.create_club_fundraiser_3rdplace.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_sports_third"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="15"
                        value={sportsThird}
                        data-type="sports_third"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          return setSportsThird(e.target.value);
                        }}
                      />
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {currency} {calculateMaxPayout(sportsThird)}
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col">
                      <span className="light_color text-white">
                        {!cfLabels.create_club_fundraiser_club
                          ? "Club"
                          : cfLabels.create_club_fundraiser_club.label_name}
                      </span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_club"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="10"
                        value={sportsClub}
                        data-type="sports_club"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          return setSportsClub(e.target.value);
                        }}
                      />
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {currency} {calculateMaxPayout(sportsClub)} Potential
                          Earnings
                        </span>
                      </span>
                    </div>
                    <div className="col-12 mt-4 club_fundraiser_col">
                      <span className="light_color text-white">Rake</span>
                      <input
                        type="number"
                        maxLength="50"
                        id="create_club_fundraiser_club"
                        className="create_club_fundraiser_select_mini mini_margin_left col-xl-4 col-lg-4"
                        placeholder="10"
                        value={rake}
                        data-type="rake"
                        min="0"
                        max="100"
                        onChange={(e) => {
                          return setRake(e.target.value);
                        }}
                      />
                      <span className="mini_margin_left club_fundraiser_right">
                        <span className="mini_margin_left club_fundraiser_right text-white">
                          {currency} {calculateRake(rake)} Site Rake
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row m-0 mt-4 create_club_fundraiser_btn">
                <div className="col-12">
                  <ButtonPrimary
                    classValue="btn_primary btn_create_club_fundraiser"
                    datapath="my_competition"
                    type="submit"
                    form="form_club_fundraiser"
                    textValue={
                      !cfLabels.create_club_fundraiser_submit
                        ? "Submit"
                        : cfLabels.create_club_fundraiser_submit.label_name
                    }
                    onClick={getClubFundraiserData}
                  />
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      {loader}
      {alert}
      <Footer />
    </React.Fragment>
  );
}
