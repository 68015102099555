import React, { useContext, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate, Link } from "react-router-dom";
import "../my-competitions/Competitions.css";
import Moment from "react-moment";
import useLoading from "../../hooks/UseLoading";
import useAlert from "../../hooks/UseAlert";
import moment from "moment";
import ApiContext from "../../providers/Api/ApiContext";

export default function LiveCompetition(props) {
  const { api, soccerApi } = useContext(ApiContext);
  const [loading, setLoading] = useState(true);
  const [liveCompetitions, setLiveCompetitions] = useState([]);
  const [soccerLiveCompetitions, setSoccerLiveCompetitions] = useState([]);
  const [checkData, setCheckData] = useState(false);
  const [privateCompetitions, setPrivateCompetitions] = useState([]);
  const [totalLiveCompetitions, setTotalLiveCompetitions] = useState([]);
  const [totalPrivateCompetitions, setTotalPrivateCompetitions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [alert, alert_notify] = useAlert();
  const [startTime, setStartTime] = useState([]);
  const [privateStartTime, setPrivateStartTime] = useState([]);
  const navigate = useNavigate();
  var competitions_titles = [
    "Sport",
    "Next Match",
    "League",
    "Entry Fee",
    "Entries",
    "End Date",
    "Position",
  ];
  const [loader, showLoader, hideLoader] = useLoading();
  var total_pages = 0;
  var pages_length = 0;
  var pages_array = [];

  const fetchSoccerLiveCompetitions = () => {
    soccerApi
      .get("/contest/live_contest")
      .then((response) => {
        var data = response.data.data.result;
        if (response.status === 200) {
          if (data !== undefined) {
            var startTimeArray = [];
            var dateTimeFormat = "DD MMM YYYY HH:mm";
            data.map((value) => {
              if (value.next_match_started != null) {
                var dateTimeUtc = moment.utc(value.next_match_started);
                var localDate = dateTimeUtc.local();
                var competitionStartAt = localDate.format(dateTimeFormat);
                var currentDate = moment().unix();
                var currentDateFormated = moment
                  .unix(currentDate)
                  .format(dateTimeFormat);
                const differenceInMinutes = moment(competitionStartAt).diff(
                  currentDateFormated,
                  "minutes"
                );
                if (differenceInMinutes < 1440 && differenceInMinutes > 60) {
                  startTimeArray.push(differenceInMinutes);
                } else if (
                  differenceInMinutes < 60 &&
                  differenceInMinutes > 0
                ) {
                  startTimeArray.push(differenceInMinutes);
                } else if (differenceInMinutes > 1440) {
                  startTimeArray.push(differenceInMinutes);
                } else {
                  startTimeArray.push(null);
                }
              }
            });
            setSoccerLiveCompetitions(data);
            setLoading(false);
            setCheckData(true);
            //setStartTime(startTimeArray);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log("401");

          //navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  // Fetch all live competitions
  const fetchLiveCompetitions = (data) => {
    var live_competitions_url = "/api/my_contest/live_contest";
    if (!data) {
      var body = {
        current_page: 1,
        items_perpage: 10,
      };
    } else {
      var body = data;
    }
    showLoader();
    api
      .post(live_competitions_url, body)
      .then((response) => {
        fetchSoccerLiveCompetitions();
        var data = response.data.Data.data.result;
        if (response.status === 200) {
          if (data !== undefined) {
            var startTimeArray = [];
            var dateTimeFormat = "DD MMM YYYY HH:mm";
            data.map((value) => {
              if (value.next_match_started != null) {
                var dateTimeUtc = moment.utc(value.next_match_started);
                var localDate = dateTimeUtc.local();
                var competitionStartAt = localDate.format(dateTimeFormat);
                var currentDate = moment().unix();
                var currentDateFormated = moment
                  .unix(currentDate)
                  .format(dateTimeFormat);
                const differenceInMinutes = moment(competitionStartAt).diff(
                  currentDateFormated,
                  "minutes"
                );
                if (differenceInMinutes < 1440 && differenceInMinutes > 60) {
                  startTimeArray.push(differenceInMinutes);
                } else if (
                  differenceInMinutes < 60 &&
                  differenceInMinutes > 0
                ) {
                  startTimeArray.push(differenceInMinutes);
                } else if (differenceInMinutes > 1440) {
                  startTimeArray.push(differenceInMinutes);
                } else {
                  startTimeArray.push(null);
                }
              }
            });
            setLiveCompetitions(data);
            setLoading(false);
            setCheckData(true);
            setStartTime(startTimeArray);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        console.log(err);
        navigate("/login");
      });
  };

  // Fetch all private cometitions
  const fetchPrivateCompetitions = (data) => {
    var private_competitions_url = "/api/my_contest/private_contest";
    if (!data) {
      var body = {
        current_page: 1,
        items_perpage: 10,
      };
    } else {
      var body = data;
    }
    showLoader();
    api
      .post(private_competitions_url, body)
      .then((response) => {
        var data = response.data.Data.data.result;
        if (response.status === 200) {
          if (data !== undefined) {
            var data_array = [];
            var privateStartArray = [];
            var dateTimeFormat = "DD MMM YYYY HH:mm";
            data.map((value) => {
              var dateTimeUtc = moment.utc(value.end_at);
              var localDate = dateTimeUtc.local();
              var competitionEndAt = localDate.format(dateTimeFormat);
              var competitionEndAtUnix = moment(
                competitionEndAt,
                "DD MMM YYYY HH:mm"
              ).unix();
              var currentDate = moment().unix();
              if (currentDate < competitionEndAtUnix) {
                data_array.push(value);
                var privateStartTimeUtc = moment.utc(value.next_match_started);
                var privateStartLocalDate = privateStartTimeUtc.local();
                var privateStartAt =
                  privateStartLocalDate.format(dateTimeFormat);
                var currentDateFormated = moment
                  .unix(currentDate)
                  .format(dateTimeFormat);
                const privateDifferenceInMinutes = moment(privateStartAt).diff(
                  currentDateFormated,
                  "minutes"
                );
                if (
                  privateDifferenceInMinutes < 1440 &&
                  privateDifferenceInMinutes > 60
                ) {
                  privateStartArray.push(privateDifferenceInMinutes);
                } else if (
                  privateDifferenceInMinutes < 60 &&
                  privateDifferenceInMinutes > 0
                ) {
                  privateStartArray.push(privateDifferenceInMinutes);
                } else if (privateDifferenceInMinutes > 1440) {
                  privateStartArray.push(privateDifferenceInMinutes);
                } else {
                  privateStartArray.push(null);
                }
              }
            });
            setPrivateCompetitions(data_array);
            setLoading(false);
            setCheckData(true);
            setPrivateStartTime(privateStartArray);
          }
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
        console.log(err);
        navigate("/login");
      });
  };

  const getTotalLiveCompetitions = () => {
    var live_competitions_url = "/api/my_contest/live_contest";
    var body = {};
    showLoader();
    api
      .post(live_competitions_url, body)
      .then((response) => {
        if (response.status === 200) {
          setTotalLiveCompetitions(response.data.Data.data.total);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const getTotalPrivateCompetitions = () => {
    var private_competitions_url = "/api/my_contest/private_contest";
    var body = {};
    showLoader();
    api
      .post(private_competitions_url, body)
      .then((response) => {
        if (response.status === 200) {
          setTotalPrivateCompetitions(response.data.Data.data.total);
        }
        hideLoader();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/login");
        } else {
          alert_notify("error", err.response.data.Error);
        }
        hideLoader();
      });
  };

  const fetchCompetitionsData = (e) => {
    var index = e.target.getAttribute("data-id");
    var mode = e.currentTarget.getAttribute("data-mode");
    if (!mode) {
      var data = {
        current_page: index,
        items_perpage: 10,
      };
      fetchPrivateCompetitions(data);
      fetchLiveCompetitions(data);
      setActivePage(Number(index));
    } else {
      var active_index = document
        .querySelector(".page_number.active")
        .getAttribute("data-id");
      if (mode == "prev") {
        if (active_index > 1) {
          var new_index = Number(active_index) - 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
          };
          fetchPrivateCompetitions(data);
          fetchLiveCompetitions(data);
          setActivePage(new_index);
        } else {
          var new_index = 1;
        }
      } else {
        if (active_index < pages_length) {
          var new_index = Number(active_index) + 1;
          var data = {
            current_page: new_index,
            items_perpage: 10,
          };
          fetchPrivateCompetitions(data);
          fetchLiveCompetitions(data);
          setActivePage(new_index);
        } else {
          var new_index = pages_length;
        }
      }
    }
  };

  useEffect(() => {
    getTotalLiveCompetitions();
    getTotalPrivateCompetitions();
    fetchPrivateCompetitions();
    fetchLiveCompetitions();
  }, [navigate]);

  const renderGameCardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Game Card
      </Tooltip>
    );
  };

  const renderLeaderBoardTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Leaderboard
      </Tooltip>
    );
  };

  if (!loading) {
    if (!liveCompetitions) {
      setLiveCompetitions([]);
      setPrivateCompetitions([]);
      setCheckData(false);
    }
  }

  total_pages = totalLiveCompetitions;
  pages_length = Math.ceil(total_pages / 10);

  for (var i = 0; i < pages_length; i++) {
    pages_array.push(i + 1);
  }
  const getStartTime = (time) => {
    var waiting_time = 0;
    var waiting_type = "Minutes";
    if (time > 1440) {
      var waiting_days = Number(time);
      var wait_day = Math.round(waiting_days / 1440);
      waiting_time = wait_day;
      waiting_type = "Days";
    } else if (time <= 1440 && time >= 60) {
      var waiting_hours = Number(time) / 60;
      waiting_time = Math.ceil(waiting_hours);
      waiting_type = "Hours";
    } else if (time < 60) {
      var waiting_minutes = time;
      var total_minutes = waiting_minutes;
      waiting_time = Math.ceil(total_minutes);
      waiting_type = "Minutes";
    } else {
      waiting_time = time;
      waiting_type = "Undefined";
    }
    return { time: waiting_time, type: waiting_type };
  };
  var index_live = 0;
  var index_private = 0;
  return (
    <React.Fragment>
      <div className="competitions mt-3">
        <div className="row competitions_icons_row">
          <span
            className="public_icon text-white"
            style={{ textTransform: "capitalize" }}
          >
            <img src="/assets/icons/public.svg" alt="public-icon" />
            {!props.labels.live_public_label
              ? "Public"
              : props.labels.live_public_label.label_name}
          </span>
          <span
            className="private_icon text-white"
            style={{ textTransform: "capitalize" }}
          >
            <img src="/assets/icons/private.svg" alt="public-icon" />
            {!props.labels.live_private_label
              ? "Private"
              : props.labels.live_private_label.label_name}
          </span>
        </div>
        <div className="row competitions_titles">
          <div className="competitions_public_private_col"></div>
          <div className="col-12 col-xl-2 col-lg-2 competition_width">
            {!props.labels.my_comp_live_sport
              ? "Sport"
              : props.labels.my_comp_live_sport.label_name}
          </div>
          <div className="col">
            {!props.labels.live_league_label
              ? "League"
              : props.labels.live_league_label.label_name}
          </div>
          <div className="col-12 col-xl-2 col-lg-2 competition_width">
            {!props.labels.my_comp_live_next_match
              ? "Next Game"
              : props.labels.my_comp_live_next_match.label_name}
          </div>
          <div className="competitions_entry_fee">
            {!props.labels.my_comp_live_entry_fee
              ? "Entry Fee"
              : props.labels.my_comp_live_entry_fee.label_name}
          </div>
          <div className="competitions_entries">
            {!props.labels.my_comp_live_entries
              ? "Entries"
              : props.labels.my_comp_live_entries.label_name}
          </div>
          <div className="col-12 col-xl-2 col-lg-2 competition_width">
            {!props.labels.live_end_date_label
              ? "End Date"
              : props.labels.live_end_date_label.label_name}
          </div>
          <div className="competition_position">
            {!props.labels.my_comp_live_position
              ? "Position"
              : props.labels.my_comp_live_position.label_name}
          </div>
          <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons"></div>
        </div>
        {!loading && checkData
          ? privateCompetitions.map((value, index) => {
              var sport = value.sport_name;
              var league = value.league_name;
              var next_match_started_private = value.next_match_started;
              var league_country = value.league_country;
              var entry_fee = value.entry_fee;
              var position = value.rank;
              var end_at = moment(value.end_at, "YYYY-MM-DD HH:mm").format(
                "DD MMM YYYY"
              );
              var contest_id = value.contest_id;
              var total_user_joined = value.total_user_joined;
              var size = value.size;
              var sport_id = value.sport_id;
              var uid = value.contest_uid;
              var status = value.status;
              var started_private_html = "";
              var waiting_private_html = "";

              // Check with status for private competitions
              if (status != 4 && status != 5) {
                if (next_match_started_private != null) {
                  var waiting_time = getStartTime(
                    privateStartTime[index_private]
                  );
                  waiting_private_html = (
                    <div>
                      <span>{waiting_time?.time}</span>
                      <span className="hours_countdown">
                        {waiting_time?.type}
                      </span>
                    </div>
                  );
                  index_private++;
                }
                if (entry_fee === "0.00" || entry_fee === 0) {
                  entry_fee = "Free to Play";
                }
                if (league_country !== undefined && league_country !== "") {
                  league_country = league_country.toLowerCase();
                }
                if (league !== undefined && league !== "") {
                  league = league.toLowerCase();
                }

                if (sport_id == "13") {
                  var link = "/golf/gamecard/" + sport_id + "/" + uid;
                  league_country = "";
                  var leaderboard_link =
                    "/golf/leaderboard/" + sport_id + "/" + uid;
                } else if (sport_id == "1") {
                  var link = "/soccer/gamecard/" + sport_id + "/" + uid;
                  var leaderboard_link = "/leaderboard/" + sport_id + "/" + uid;
                } else {
                  var link = "/gamecard/" + sport_id + "/" + uid;
                  var leaderboard_link = "/leaderboard/" + sport_id + "/" + uid;
                }

                return (
                  <div
                    className="row competition transaction m-0"
                    data-contestid={contest_id}
                    row-index={index}
                    key={index}
                  >
                    <div className="competitions_public_private_col">
                      <span className="public_icon">
                        <img
                          src="/assets/icons/private_primary.svg"
                          alt="private-icon"
                        />
                      </span>
                    </div>
                    <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons_mobile">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderGameCardTooltip}
                      >
                        <Link
                          className="gamecard_icon"
                          data-path="gamecard"
                          data-sportid={sport_id}
                          data-uid={uid}
                          to={link}
                        >
                          <img
                            src="assets/icons/gamecard_primary.svg"
                            alt="game-card-icon"
                          />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderLeaderBoardTooltip}
                      >
                        <Link
                          className="leaderboard_icon"
                          data-path="leaderboard"
                          data-sportid={sport_id}
                          data-uid={uid}
                          to={leaderboard_link}
                        >
                          <img
                            src="assets/icons/leaderboard.svg"
                            alt="leaderboard-icon"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>
                    <div className="col-12 col-lg-2 col-xl-2 d-flex competition_width competition_col">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[0]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 d-flex">
                        <div className="competitions_public_private_col_mobile">
                          <span className="public_icon">
                            <img
                              src="/assets/icons/private.svg"
                              alt="private-icon"
                            />
                          </span>
                        </div>
                        {sport}
                      </span>
                    </div>
                    <div className="col d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[2]}
                      </span>
                      <span
                        className="transaction_value col-7 col-lg-12 col-xl-12"
                        style={{ textTransform: "uppercase" }}
                      >
                        {league_country} {league}
                      </span>
                    </div>
                    <div className="col-12 col-xl-2 col-lg-2 d-flex competition_width competition_col">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[1]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {next_match_started_private != null ? (
                          <div>{waiting_private_html}</div>
                        ) : moment(value.end_at, "YYYY-MM-DD HH:mm").unix() >
                            moment().unix() && sport_id != 13 ? (
                          <div className="completed_badge_live">
                            {" "}
                            <span className="d-flex justify-content-center">
                              Started
                            </span>{" "}
                          </div>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>
                    <div className="competitions_entry_fee d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[3]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 alignText">
                        {entry_fee}
                      </span>
                    </div>
                    <div className="competitions_entries d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[4]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {total_user_joined}/{size}
                      </span>
                    </div>
                    <div className="col-12 col-xl-2 col-lg-2 d-flex competition_width competition_col">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[5]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12 d-flex">
                        {end_at}
                      </span>
                    </div>
                    <div className="competition_position d-flex">
                      <span className="transaction_title col-5 col-lg-12 col-xl-12">
                        {competitions_titles[6]}
                      </span>
                      <span className="transaction_value col-7 col-lg-12 col-xl-12">
                        {position || "-"}
                      </span>
                    </div>
                    <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderGameCardTooltip}
                      >
                        <Link
                          className="gamecard_icon"
                          data-path="gamecard"
                          data-sportid={sport_id}
                          data-uid={uid}
                          to={link}
                        >
                          <img
                            src="assets/icons/gamecard_primary.svg"
                            alt="game-card-icon"
                          />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderLeaderBoardTooltip}
                      >
                        <Link
                          className="leaderboard_icon"
                          data-path="leaderboard"
                          data-sportid={sport_id}
                          data-uid={uid}
                          to={leaderboard_link}
                        >
                          <img
                            src="assets/icons/leaderboard_primary.svg"
                            alt="leaderboard-icon"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>
                  </div>
                );
              }
            })
          : ""}
        {!loading && checkData
          ? liveCompetitions
              .concat(soccerLiveCompetitions)
              .map((value, index) => {
                var sport = value.sport_name;
                var league = value.league_name;
                var next_match_started = value.next_match_started;
                var league_country = value.league_country;
                var entry_fee = value.entry_fee;
                var position = value.rank;
                var end_at = moment(value.end_at, "YYYY-MM-DD HH:mm").format(
                  "DD MMM YYYY"
                );
                var contest_id = value.contest_id;
                var total_user_joined = value.total_user_joined;
                var size = value.size;
                var sport_id = value.sport_id;
                var uid = value.contest_uid;
                var status = value.status;
                var started_html = "";
                var waiting_html = "";
                // Check with status for live competitions

                if (status != 4 && status != 5) {
                  if (next_match_started != null) {
                    var waiting_time = getStartTime(startTime[index_live]);
                    waiting_html = (
                      <div>
                        <span>{waiting_time?.time}</span>
                        <span className="hours_countdown">
                          {waiting_time?.type}
                        </span>
                      </div>
                    );
                    index_live++;
                  }
                  if (entry_fee === "0.00" || entry_fee === 0) {
                    entry_fee = "Free to Play";
                  }
                  if (league_country != undefined && league_country != "") {
                    league_country = league_country.toLowerCase();
                  }
                  if (league != undefined && league != "") {
                    league = league.toLowerCase();
                  }

                  if (sport_id == "13") {
                    var link = "/golf/gamecard/" + sport_id + "/" + uid;
                    league_country = "";
                    var leaderboard_link =
                      "/golf/leaderboard/" + sport_id + "/" + uid;
                  } else if (sport_id == "1") {
                    var link = "/soccer/gamecard/" + sport_id + "/" + uid;
                    var leaderboard_link =
                      "/leaderboard/" + sport_id + "/" + uid;
                  } else {
                    var link = "/gamecard/" + sport_id + "/" + uid;
                    var leaderboard_link =
                      "/leaderboard/" + sport_id + "/" + uid;
                  }
                  return (
                    <div
                      className="row competition transaction m-0"
                      data-contestid={contest_id}
                      row-index={index}
                      key={index}
                    >
                      <div className="competitions_public_private_col">
                        <span className="public_icon">
                          <img
                            src="/assets/icons/public_primary.svg"
                            alt="public-icon"
                          />
                        </span>
                      </div>
                      <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons_mobile">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderGameCardTooltip}
                        >
                          <Link
                            className="gamecard_icon"
                            data-path="gamecard"
                            data-sportid={sport_id}
                            data-uid={uid}
                            to={link}
                          >
                            <img
                              src="assets/icons/gamecard_primary.svg"
                              alt="game-card-icon"
                            />
                          </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderLeaderBoardTooltip}
                        >
                          <Link
                            className="leaderboard_icon"
                            data-path="leaderboard"
                            data-sportid={sport_id}
                            data-uid={uid}
                            to={leaderboard_link}
                          >
                            <img
                              src="assets/icons/leaderboard_primary.svg"
                              alt="leaderboard-icon"
                            />
                          </Link>
                        </OverlayTrigger>
                      </div>
                      <div className="col-12 col-lg-2 col-xl-2 d-flex competition_width competition_col">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[0]}
                        </span>
                        <span className="transaction_value col-7 col-lg-12 col-xl-12 d-flex">
                          <div className="competitions_public_private_col_mobile">
                            <span className="public_icon">
                              <img
                                src="/assets/icons/public.svg"
                                alt="private-icon"
                              />
                            </span>
                          </div>
                          {sport}
                        </span>
                      </div>
                      <div className="col d-flex">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[2]}
                        </span>
                        <span
                          className="transaction_value col-7 col-lg-12 col-xl-12"
                          style={{ textTransform: "uppercase" }}
                        >
                          {league_country} {league}
                        </span>
                      </div>
                      <div className="col-12 col-xl-2 col-lg-2 d-flex competition_width competition_col">
                        <span className="transaction_title col-5">
                          {competitions_titles[1]}
                        </span>
                        <span className="transaction_value col-7">
                          {next_match_started != null ? (
                            <div>{waiting_html}</div>
                          ) : moment(value.end_at, "YYYY-MM-DD HH:mm").unix() >
                              moment().unix() && sport_id != 13 ? (
                            <div className="completed_badge_live">
                              {" "}
                              <span className="d-flex justify-content-center">
                                Started
                              </span>{" "}
                            </div>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                      <div className="competitions_entry_fee d-flex">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[3]}
                        </span>
                        <span className="transaction_value col-7 col-lg-12 col-xl-12 alignText">
                          {entry_fee}
                        </span>
                      </div>
                      <div className="competitions_entries d-flex">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[4]}
                        </span>
                        <span className="transaction_value col-7 col-lg-12 col-xl-12">
                          {total_user_joined}/{size}
                        </span>
                      </div>
                      <div className="col-12 col-xl-2 col-lg-2 d-flex competition_width competition_col">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[5]}
                        </span>
                        <span className="transaction_value col-7 col-lg-12 col-xl-12 d-flex">
                          {end_at}
                        </span>
                      </div>
                      <div className="competition_position d-flex">
                        <span className="transaction_title col-5 col-lg-12 col-xl-12">
                          {competitions_titles[6]}
                        </span>
                        <span className="transaction_value col-7 col-lg-12 col-xl-12">
                          {position || "-"}
                        </span>
                      </div>
                      <div className="col-12 col-lg-1 col-xl-1 d-flex justify-content-center competition_icons">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderGameCardTooltip}
                        >
                          <Link
                            className="gamecard_icon"
                            data-path="gamecard"
                            data-sportid={sport_id}
                            data-uid={uid}
                            to={link}
                          >
                            <img
                              src="assets/icons/gamecard_primary.svg"
                              alt="game-card-icon"
                            />
                          </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderLeaderBoardTooltip}
                        >
                          <Link
                            className="leaderboard_icon"
                            data-path="leaderboard"
                            data-sportid={sport_id}
                            data-uid={uid}
                            to={leaderboard_link}
                          >
                            <img
                              src="assets/icons/leaderboard_primary.svg"
                              alt="leaderboard-icon"
                            />
                          </Link>
                        </OverlayTrigger>
                      </div>
                    </div>
                  );
                }
              })
          : ""}
      </div>
      {pages_array.length > 1 ? (
        <div className="pagination d-flex justify-content-end mt-3">
          <div
            className="page"
            data-mode="prev"
            onClick={fetchCompetitionsData}
          >
            <span>
              <img src="/assets/icons/arrow-left.svg" alt="arrow-left" />
            </span>
          </div>
          {pages_array.map((row, index) => {
            return (
              <div
                className={
                  activePage === index + 1
                    ? "page page_number active"
                    : "page page_number "
                }
                key={index}
                data-id={index + 1}
                onClick={fetchCompetitionsData}
              >
                {row}
              </div>
            );
          })}
          <div
            className="page"
            data-mode="next"
            onClick={fetchCompetitionsData}
          >
            <span>
              <img src="/assets/icons/arrow-right.svg" alt="arrow-right" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {loader}
      {alert}
    </React.Fragment>
  );
}
