import React, { useState } from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expiryTimestamp }) => {
  const [isLive, setIsLive] = useState(false);
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => setIsLive(true),
  });
  return (
    <div>
      {days <= 0 ? (
        <>
          <span>{hours < 10 ? `0${hours}` : hours}</span>:
          <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </>
      ) : (
        <span>{expiryTimestamp.format("ddd HH:MM")}</span>
      )}
    </div>
  );
};

export default Timer;
